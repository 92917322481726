<template>
    <v-select
        v-model="modelData"
        :items="tiers"
        :item-text="defaultItemText"
        :item-value="defaultItemValue"
        multiple
        hide-details
        prepend-inner-icon="mdi-account"
        outlined
        :placeholder="placeholder"
        dense
        :disabled="isDisabled"
    >
        <template v-slot:selection="{ index }">
            <div color="deep-purple lighten-5" v-if="index === 0">
                <v-chip small label color="deep-purple lighten-5 deep-purple--text"
                    >{{ currentStudentsPicked.length }} {{ currentStudentsPicked.length > 1 ? $t('assignments.utils.students.multiple') : $t('assignments.utils.students.single') }}</v-chip
                >
            </div>
        </template>

        <template v-slot:prepend-item>
            <v-col class="py-5">
                <div class="px-5 mb-3">
                    <span
                        class="body-2 font-weight-bold "
                        data-cy="generalFilter-studentsDetailTitle-span"
                    >
                        {{ $t('assignments.placeholders.studentsSelection') }}
                    </span>
                    <v-text-field
                        data-cy="generalFilter-studentsFilter-input"
                        v-model="searchStudent"
                        append-icon="mdi-magnify"
                        :placeholder="$t('assignments.placeholders.searchStudent')"
                        outlined
                        color="deep-purple lighten-3"
                        hide-details
                        dense
                        class="mt-3 text-capitalize"
                    ></v-text-field>
                </div>

                <v-list-item class="mb-3" @click="handleAllStudentsOption">
                    <v-list-item-action class="mr-3 no-margin">
                        <v-checkbox v-model="modelData" color="primary" value="ALL"></v-checkbox>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>
                            {{ $t('assignments.inputs.students.all') }}
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>

                <hr>
            </v-col>
        </template>

        <template v-slot:item="{ item, attrs, on }">
            <v-col>
                <!-- Tiers -->
                <v-list-item v-bind="attrs" v-on="on" class="pa-0" v-if="item.students.length > 0">
                    <v-list-item-action class="mr-3 no-margin" >
                        <v-checkbox v-model="modelData" color="primary" :value="item.id" @click="handleTierPicked(item.id)"></v-checkbox>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title :id="attrs['aria-labelledby']">
                            <v-chip
                                small
                                label
                                :color="getTierColor(item.id)"
                                class="white--text"
                                >{{ $i18n.locale === 'es' ? item.displayName : item.name}}</v-chip
                            >
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>

                <!-- Students -->
                <v-list-item
                    v-bind="attrs"
                    v-on="on"
                    class="pa-0 pl-9"
                    v-for="student in item.students"
                    :key="student.id"
                >
                    <v-list-item-action class="mr-3 no-margin">
                        <v-checkbox
                            v-model="modelData"
                            color="primary"
                            :value="student.id"
                        ></v-checkbox>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title :id="attrs['aria-labelledby']">
                            <span class="text-capitalize">
                                {{ `${student.firstname ? student.firstname.toLowerCase() : ''} ${student.lastname ? student.lastname.toLowerCase() : ''}` }}
                            </span>
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-col>
        </template>
    </v-select>
</template>

<script>
export default {
    name: 'StudentsSelectPerTier',
    props: {
        students: {
            type: Array,
            required: true,
        },
        model: {
            required: true,
        },
        defaultItemText: {
            type: String,
            required: false,
            default: 'name',
        },
        defaultItemValue: {
            type: String,
            required: false,
            default: 'id',
        },
        placeholder: {
            type: String,
            required: false,
            default: 'Select students',
        },
        isDisabled: {
            type: Boolean,
            required: false,
            default: false,
        }
    },
    data() {
        return {
            modelData: this.model,
            searchStudent: '',
        }
    },
    beforeMount() {
        this.setDefaultStudentsPerTier()
    },
    methods: {
        getTierColor(tierID) {
            switch (tierID) {
                case 'TIER_GROUP_1':
                    return 'green darken-2'
                case 'TIER_GROUP_2':
                    return 'amber accent-3'
                case 'TIER_GROUP_3':
                    return 'red darken-2'
                case 'NO_TIER':
                    return 'grey darken-1'
                default:
                    return 'green darken-3'
            }
        },
        handleTierPicked(tierID) {
            this.$emit('tier-picked', tierID)
        },
        isTierFullPicked(tierID) {
            const students = Array.from(this.students)
            const tier = this.tiers.find(tier => tier.id === tierID)
            if(!tier) return false

            const studentsPickedTier = students.filter(student => this.model.includes(student.id) && student.tier === tierID)
            return studentsPickedTier.length === tier.students.length
        },
        handleAllStudentsOption() {
            this.$emit('all-students-option')
        },
        setDefaultStudentsPerTier() {
            this.tiers.forEach((tier) => {
                const students = Array.from(this.students).filter(student => student.id)
                if(tier.id === 'NO_TIER') {
                    tier.students = students.filter((student) =>  !student.tier || !['TIER_GROUP_1', 'TIER_GROUP_2', 'TIER_GROUP_3'].includes(student.tier))
                } else {
                    tier.students = students.filter((student) => student.tier === tier.id)
                }
            })
        },
    },
    computed: {
        tiers() {
            return [
                {
                    id: 'TIER_GROUP_1',
                    name: 'Tier 1',
                    displayName: this.$t('assignments.utils.tiers.tier') + ' 1',
                    students: [],
                },
                {
                    id: 'TIER_GROUP_2',
                    name: 'Tier 2',
                    displayName: this.$t('assignments.utils.tiers.tier') + ' 2',
                    students: [],
                },
                {
                    id: 'TIER_GROUP_3',
                    name: 'Tier 3',
                    displayName: this.$t('assignments.utils.tiers.tier') + ' 3',
                    students: [],
                },
                {
                    id: 'NO_TIER',
                    name: 'No tier',
                    displayName: this.$t('assignments.utils.tiers.noTier'),
                    students: [],
                },
                {
                    id: 'STUDENTS',
                    name: 'Students',
                    displayName: 'Students',
                    students: null,
                },
            ]
        },
        isTier1Picked() {
            return this.isTierFullPicked('TIER_GROUP_1')
        },
        isTier2Picked() {
            return this.isTierFullPicked('TIER_GROUP_2')
        },
        isTier3Picked() {
            return this.isTierFullPicked('TIER_GROUP_3')
        },
        isNoTierPicked() {
            return this.isTierFullPicked('NO_TIER')
        },
        currentStudentsPicked() {
            const itemsPicked = Array.from(this.model)
            const filtered = itemsPicked.filter(id => !['ALL', 'TIER_GROUP_1', 'TIER_GROUP_2', 'TIER_GROUP_3', 'NO_TIER', 'STUDENTS'].includes(id))
            return filtered
        },
    },
    watch: {
        model() {
            this.modelData = this.model
        },
        modelData(value) {
            this.$emit('update:model', value)
        },
        searchStudent(searchString) {
            if(searchString === '' || !searchString) return this.setDefaultStudentsPerTier()
            this.tiers = this.tiers.map(tier => {
                tier.students = tier.students.filter(student => student.firstname?.toLowerCase().includes(searchString.toLowerCase()))
                return tier
            })
        },
        students() {
            this.setDefaultStudentsPerTier()
        },
    },
 
}
</script>
