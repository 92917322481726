<template>
    <v-container class="d-flex align-center justify-center pie-chart__container">
        <vue-chart :option="graphOption" class="pie-chart"></vue-chart>
    </v-container>
</template>

<script>
import { SKILLS_COLORS } from '@/data/skillsConstants'
import ECharts from 'vue-echarts'
import { use } from 'echarts/core'

import { CanvasRenderer } from 'echarts/renderers'
import { PieChart } from 'echarts/charts'
import { GridComponent, TooltipComponent } from 'echarts/components'
import router from '@/router'

import iconLOC from '@/assets/img/icons/Icon-LOC.png'
import iconIN from '@/assets/img/icons/Icon-IN.png'
import iconRV from '@/assets/img/icons/Icon-RV.png'


use([CanvasRenderer, PieChart, GridComponent, TooltipComponent])

const fullLabels = {
    "LOC": router.app.$t('reports.skills.fullName.locate'),
    "IN": router.app.$t('reports.skills.fullName.integrate'),
    "RV": router.app.$t('reports.skills.fullName.reflect'),
}

export default {
    name: 'pie-chart',
    components: {
        'vue-chart': ECharts,
    },
    props: {
        data: { required: true },
        colors: {
            required: false,
            default: () => [ SKILLS_COLORS.locate, SKILLS_COLORS.integrate, SKILLS_COLORS.reflect]
        },
        nameOfChart: {
            required: false,
            default: 'Skills'
        }
    },
    computed: {
        graphData() { return this.data  }
    },
    data() {
        return {
            graphOption: {
                tooltip: {
                    trigger: 'item',
                    formatter: function (params) {
                        return `<b>${params.name}</b> / ${fullLabels[params.name]} (${params.percent}%)<br />`;
                    }
                },
                series: [
                    {
                        name: this.nameOfChart,
                        type: 'pie',
                        radius: ['40%', '70%'],
                        avoidLabelOverlap: false,
                        itemStyle: {
                            borderRadius: 0,
                            borderColor: '#fff',
                            borderWidth: 2,
                            formatter: '{c}%',
                        },
                        label: {
                            show: false,
                            position: 'center',
                            formatter: '{c}%',
                        },
                        emphasis: {
                            label: {
                                show: true,
                                fontSize: 12,
                                fontWeight: 'bold',
                                //TODO: make this image dynamic
                                formatter: (params) => {
                                    switch (params.name) {
                                        case 'LOC':
                                            return '{imgLOC|}'
                                        case 'IN':
                                            return '{imgIN|}'
                                        case 'RV':
                                            return '{imgRV|}'
                                        default:
                                            return '{img}'
                                    }
                                },
                                rich: {
                                    img: {
                                        height: 40,
                                        width: 40,
                                        align: 'center',
                                        backgroundColor: {
                                            image: iconIN,
                                        },
                                    },
                                    imgIN: {
                                        height: 40,
                                        width: 40,
                                        align: 'center',
                                        backgroundColor: {
                                            image: iconIN,
                                        },
                                    },
                                    imgLOC: {
                                        height: 40,
                                        width: 40,
                                        align: 'center',
                                        backgroundColor: {
                                            image: iconLOC,
                                        },
                                    },
                                    imgRV: {
                                        height: 40,
                                        width: 40,
                                        align: 'center',
                                        backgroundColor: {
                                            image: iconRV,
                                        },
                                    },
                                },
                            }
                        },
                        data: this.data,
                        color: this.colors
                    },
                ],
                legend: {
                    bottom: '0%',
                    left: 'center',
                    icon: 'circle',
                    formatter: function (name) {
                        // TODO: add total percentages
                        return `${name}`
                    },
                },
            },
        }
    },
}
</script>

<style lang="scss" scoped>
.pie-chart__container {
    position: relative;
    width: 90%;
}
</style>
