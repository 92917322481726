<template>
    <v-container :loading="loadingData" class="py-0 my-0">
        <v-btn
            color="deep-purple lighten-5"
            rounded
            elevation="0"
            small
            class="text-capitalize mb-6"
            @click="$router.go(-1)"
        >
            <v-icon class="mr-1" color="deep-purple" small>mdi-arrow-left</v-icon>
            <span class="black--text font-weight-bold">
                {{ $t('library.goBack') }}
            </span>
        </v-btn>
        <v-card elevation="0" rounded="xl">
            <v-row>
                <v-col cols="12">
                    <h2 class="pa-6">{{ $t('library.book_information') }}</h2>
                    <hr />
                </v-col>
            </v-row>
            <v-row class="px-6 mb-9">
                <v-col cols="12">
                    <v-row>
                        <v-col cols="4">
                            <v-img
                                :src="`https://assets.beereaders.com/lbr/image/${book.resourceDir}/cover_small.jpg?v=${new Date().getTime()}`"
                                width="100%"
                                height="100%"
                                class="border-radius-1"
                                v-if="book"
                            />
                            <v-skeleton-loader type="image" width="100%" height="100%" v-else />
                        </v-col>
                        <v-col cols="5">
                            <v-container class="py-0 my-0" v-if="book">
                                <!-- --------------------- -->
                                <!-- Book Main Information -->
                                <!-- --------------------- -->
                                <h1 class="black--text font-weight-bold">{{ book.title }}</h1>
                                <h3 class="font-size-18 grey--text lighten-1">
                                    {{ author }}
                                </h3>

                                <v-row class="pa-2">
                                    <!-- ---------- -->
                                    <!-- Book Genre -->
                                    <!-- ---------- -->
                                    <v-chip
                                        v-if="book.textType"
                                        class="ma-1"
                                        color="deep-purple lighten-5"
                                        small
                                    >
                                        <span class="primary--text font-weight-medium">
                                            {{ book.textType.name }}
                                        </span>
                                    </v-chip>

                                    <!-- ----------- -->
                                    <!-- Book Topics -->
                                    <!-- ----------- -->
                                    <v-chip
                                        class="ma-1"
                                        v-for="category of book.categoryDTOList"
                                        :key="category.id"
                                        color="deep-purple lighten-5"
                                        small
                                    >
                                        <span class="primary--text font-weight-medium">
                                            {{ category.name }}
                                        </span>
                                    </v-chip>
                                </v-row>
                                <!-- ------------- -->
                                <!-- Book Abstract -->
                                <!-- ------------- -->
                                <div class="book-abstract__container">
                                    <p class="body-2 mt-3">
                                        {{ book.bookAbstract }}
                                    </p>
                                </div>

                                <!-- -------------------------- -->
                                <!-- Book Aditional Information -->
                                <!-- -------------------------- -->
                                <h5 class="font-weight-bold my-3">
                                    {{ $t('library.additional_information') }}
                                </h5>

                                <v-row class="px-3">
                                    <!-- Number of pages -->
                                    <div class="detail-item">
                                        <span class="body-2">{{ $t('pages') }}</span>
                                        <v-chip
                                            small
                                            label
                                            class="pa-1 mx-1"
                                            color="deep-purple lighten-5"
                                        >
                                            <span class="primary--text font-weight-bold">
                                                {{ book.structTotalPages }}
                                            </span>
                                        </v-chip>
                                    </div>

                                    <!-- Number of questions -->
                                    <div class="detail-item">
                                        <span class="body-2">{{
                                            $t('library.number_of_questions')
                                        }}</span>
                                        <v-chip
                                            small
                                            label
                                            class="pa-1 mx-1"
                                            color="deep-purple lighten-5"
                                        >
                                            <span class="primary--text font-weight-bold">
                                                {{ book.totalQuestions || 0 }}
                                            </span>
                                        </v-chip>
                                    </div>

                                    <!-- Age range data -->
                                    <div class="detail-item">
                                        <span class="body-2">{{ $t('library.ageRange') }}: </span>
                                        <v-chip
                                            small
                                            label
                                            class="pa-1 mx-1"
                                            color="deep-purple lighten-5"
                                        >
                                            <span class="primary--text font-weight-bold">
                                                {{
                                                    $t('library.ageRangeValue', {
                                                        fromAge: book.ageRange.fromAge,
                                                        toAge: book.ageRange.toAge,
                                                    })
                                                }}
                                            </span>
                                        </v-chip>
                                    </div>

                                    <!-- Lexile data -->
                                    <div class="detail-item" v-if="book.lexile">
                                        <span class="body-2">Lexile</span>
                                        <v-chip
                                            small
                                            label
                                            class="pa-1 mx-1"
                                            color="deep-purple lighten-5"
                                        >
                                            <span class="primary--text font-weight-bold">
                                                {{ book.lexile }}
                                            </span>
                                        </v-chip>
                                    </div>

                                    <!-- Book Country -->
                                    <div v-if="bookCountry && bookCountry.name" class="detail-item">
                                        <span class="body-2">{{ $t('library.country') }}</span>
                                        <v-chip
                                            small
                                            label
                                            class="pa-1 mx-1"
                                            color="deep-purple lighten-5"
                                        >
                                            <span
                                                class="primary--text font-weight-bold text-capitalize"
                                            >
                                                {{ bookCountry.name.toLowerCase() }}
                                            </span>
                                        </v-chip>
                                    </div>
                                </v-row>

                                <!-- Books rating -->
                                <v-row class="px-3">
                                    <div class="detail-item">
                                        <span class="body-2">
                                            {{ $t('library.book_rating') }}
                                        </span>
                                        <v-chip small color="orange lighten-5" class="mx-2">
                                            <star-rating
                                                v-model="book.rating"
                                                :star-size="18"
                                                read-only
                                                :show-rating="false"
                                            />
                                        </v-chip>
                                    </div>
                                </v-row>
                                <!-- Book preview -->
                                <v-row class="px-3">
                                    <div class="detail-item">
                                        <v-btn
                                                color="secondary"
                                                outlined
                                                dense
                                                rounded
                                                small
                                                class="mt-4 text-capitalize"
                                                @click="$router.push(`/teacher/preview/${bookId}`)"
                                            >
                                                {{ $t('preview') }}
                                            </v-btn>
                                    </div>
                                </v-row>
                            </v-container>
                            <v-skeleton-loader
                                v-else
                                type="list-item-three-line, card-heading, list-item-two-line"
                            />
                        </v-col>

                        <!-- Focus Skills -->
                        <v-col cols="3" class="ml-auto">
                            <v-card elevation="0" rounded="xl" v-if="book" >
                                <div v-if="book" class="book-skills-card">
                                    <v-row align-content="center" justify="center" class="pa-5">
                                        <v-tooltip
                                            bottom
                                            right
                                            color="white"
                                            content-class="px-6 py-6 elevation-12 rounded-lg"
                                            max-width="270px"
                                        >
                                            <template v-slot:activator="{ on, attrs }">
                                                <span
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    class="black--text body-2 font-weight-medium"
                                                >
                                                    {{ $t('library.focus_skill') }}
                                                    <v-icon small color="primary"
                                                        >mdi-information</v-icon
                                                    >
                                                </span>
                                            </template>

                                            <span class="black--text">
                                                {{ $t('assignments.texts.focusSkill') }}
                                            </span>
                                        </v-tooltip>
                                    </v-row>
                                    <hr />

                                    <div>
                                        <PieChart :data="getSkillsPieGraphData" />
                                    </div>
                                </div>
                            </v-card>
                            <v-skeleton-loader type="article" v-else />

                            <!-- ------------------ -->
                            <!-- Button assign book -->
                            <!-- ------------------ -->
                            <v-row class="mx-1 my-3 mt-6">
                                <v-btn
                                    data-cy="assign-book-button"
                                    @click="openAssignBookModal"
                                    color="primary"
                                    class="w-100"
                                    elevation="0"
                                    large
                                    v-if="!loadingData"

                                >
                                    <span class="body-2 font-weight-bold text-capitalize my-3">
                                        <v-icon small class="mr-2">
                                            mdi-clipboard-check-multiple
                                        </v-icon>
                                        {{ assignmentId ? $t('assignments.actions.modify') : $t('library.assign_this_book') }}
                                    </span>

                                </v-btn>
                                <v-skeleton-loader type="button" width="100%" class="mx-1" v-else />
                            </v-row>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>

            <!-- -------- -->
            <!-- Old code -->
            <!-- -------- -->
            <!-- <v-row class="pa-3" v-if="book">
                <v-col cols="2" class="d-flex justify-start align-start flex-column">
                    <span class="font-size-20 primary--text font-weight-bold">
                        {{ $t('library.assign_this_book') }}
                    </span>
                    <div class="mt-3 d-flex justify-start align-start flex-column">
                        <span class="primary--text font-size-14 mb-2">
                            {{ $t('library.select_init_month') }}
                        </span>
                        <v-row class="pa-0 dropdown-content ml-1">
                            <v-col cols="2" class="d-flex justify-center align-center pa-0">
                                <v-icon color="accent">mdi-card</v-icon>
                            </v-col>
                            <v-col cols="10" class="pa-0 d-flex justify-center align-center">
                                <v-select
                                    color="accent"
                                    dense
                                    hide-details
                                    solo
                                    flat
                                    append-icon="mdi-chevron-down"
                                    :items="monthToShow"
                                    v-model="initMonth"
                                    @change="choseMonth"
                                />
                            </v-col>
                        </v-row>
                    </div>
                    <div class="mt-3 d-flex justify-start align-start flex-column w-100">
                        <span class="primary--text font-size-14 mb-2">
                            {{ $t('library.select_class') }}
                        </span>
                        <v-radio-group v-model="courseSelect" class="pa-0 ma-0 w-100">
                            <v-radio
                                v-for="(course, index) of courses"
                                :key="index"
                                :label="`${course.name}`"
                                :value="course.id"
                                class="radio-content px-3 py-2 w-100"
                            />
                        </v-radio-group>
                    </div>
                </v-col>
                <v-col cols="9" class="ml-auto">
                    <FunctionalCalendar
                        :is-separately="true"
                        :is-date-range="true"
                        :calendars-count="2"
                        :is-multiple="true"
                        :sunday-start="true"
                        :day-names="dateNames.days"
                        :month-names="dateNames.months"
                        :marked-dates="reservedDates.marked"
                        :marked-date-range="reservedDates.range"
                        date-format="yyyy-mm-dd"
                        ref="calendar"
                        v-on:choseDay="handledKeepStartDate()"
                        v-model="calendarData"
                    />
                </v-col>
            </v-row>
            <v-row v-if="book">
                <v-col cols="12" class="d-flex">
                    <v-btn
                        outlined
                        color="secondary"
                        class="ml-auto text-capitalize"
                        large
                        @click="activateAssignment"
                        :loading="loadingData"
                    >
                        {{
                            assignmentId
                                ? $t('library.modify_assignment')
                                : $t('library.activate_assign')
                        }}
                    </v-btn>
                </v-col>
            </v-row> -->
            <!-- -------- -->
            <!-- Old code -->
            <!-- -------- -->
        </v-card>

        <!-- ------ -->
        <!-- Modals -->
        <!-- ------ -->
        <ActivateBookAssignation
            v-if="book"
            :dialog="assignBookModal"
            :book-title="book.title"
            :book-id="Number(bookId)"
            :courses="courses"
            :students="studentsFromCourse"
            :past-assignation="assignmentId ? true : false"
            :assignation-id="assignmentId"
            :old-assignation="oldAssignation"
            @close-modal="closeAssignBookModal"
            @activate-assignation="onActivateAssignation"
            @on-select-course="onSelectCourse"
        />
        <SuccessBookAssignment
            v-if="book && !oldAssignation"
            :dialog="showSuccessActivation"
            :book-title="book.title"
            @close-modal="closeSuccessActivationModal"
            @see-more-assignments="onSeeMoreAssignments"
        />
        <SuccessBookAssignmentModification
            v-if="book && oldAssignation"
            :dialog="showSuccessActivation"
            :book-title="book.title"
            @close-modal="closeSuccessActivationModal"
            @see-more-assignments="onSeeMoreAssignments"
        />
    </v-container>
</template>

<script>
import { mapState } from 'vuex'
import bookService from '@/services/book' //eslint-disable-line
import courseBook from '@/services/courseBook' //eslint-disable-line
import Author from '@/mixins/author' //eslint-disable-line
import SuccessBookAssignment from './modals/SuccessBookAssignment.vue'
import SuccessBookAssignmentModification from './modals/SucessBookAssigmentModification.vue'
import ActivateBookAssignation from './modals/ActivateBookAssignation.vue'
import SkillBar from '../core/SkillBar.vue'
import PieChart from './shared/graphs/pieChart/index.vue'

import AnalyticEventsMixin from '@/mixins/analyticEvents' //eslint-disable-line
import { AnalyticEventTypes } from '../../constants/AnalyticEventTypes'

export default {
    name: 'ToAssignBook',
    props: {
        bookId: { type: [Number, String], default: 0 },
        courseId: { type: [Number, String], default: 0 },
        assignmentId: { type: [Number, String], default: 0 },
        start: { type: String, default: '' },
        end: { type: String, default: '' },
    },
    components: {
        SkillBar,
        SuccessBookAssignment,
        PieChart,
        ActivateBookAssignation,
        SuccessBookAssignmentModification,
    },
    data() {
        return {
            book: null,
            stats: null,
            loadingData: false,
            calendarData: {
                dateRange: {
                    start: '',
                    end: '',
                },
            },
            assignBookModal: false,
            courseSelect: 0,
            initMonth: null,
            endMonth: null,
            showModal: false,
            showSuccessActivation: false,
            originalDate: null,
            fromPastBookPage: null,
            courseSelected: null,
            tierSelected: null,
            studentsSelected: [],
            oldAssignation: null,
        }
    },
    mixins: [Author, AnalyticEventsMixin],
    computed: {
        ...mapState({
            courses: (state) => state.userData.courses,
            assignedBooksList: (state) => state.library.assignedBooksList,
            students: (state) => state.userData.students,
        }),
        dateNames() {
            return { days: this.$t('days_names'), months: this.$t('month_names') }
        },
        monthToShow() {
            let offset = 0
            const months = []
            while (offset < 12) {
                months.push({
                    text: this.$moment().add(offset, 'month').format('MMM YYYY'),
                    value: this.$moment().add(offset, 'month').format('YYYY-MM-DD'),
                })
                offset += 1
            }
            return months
        },
        rvPercent() {
            return this.stats.rv ? ((this.stats.rv / this.sumAllMeasures()) * 100).toFixed() : 0
        },
        inPercent() {
            return this.stats.in ? ((this.stats.in / this.sumAllMeasures()) * 100).toFixed() : 0
        },
        locPercent() {
            return this.stats.loc ? ((this.stats.loc / this.sumAllMeasures()) * 100).toFixed() : 0
        },
        reservedDates() {
            const vm = this
            const marked = []
            const range = []

            this.assignedBooksList.forEach((i) => {
                if (
                    i.id !== parseInt(vm.assignmentId, 10) &&
                    i.course.id === parseInt(vm.courseId, 10)
                ) {
                    marked.push({
                        date: vm.$moment(i.startingDate).format('yyyy-M-D'),
                        class: 'reserved-date',
                    })
                    marked.push({
                        date: vm.$moment(i.evaluationDate).format('yyyy-M-D'),
                        class: 'reserved-date-end',
                    })

                    range.push({
                        start: vm.$moment(i.startingDate).format('yyyy-M-D'),
                        end: vm.$moment(i.evaluationDate).format('yyyy-M-D'),
                    })
                }
            })
            return { marked, range }
        },
        assignFromPastBookPage() {
            return !!this.$route.query.pastBook
        },
        bookCountry() {
            return this.book?.countries.length > 0 ? this.book.countries[0] : null
        },
        getSkillsPieGraphData() {
            return [
                {
                    name: 'LOC',
                    value: Number(this.locPercent),
                },
                {
                    name: 'IN',
                    value: Number(this.inPercent),
                },
                {
                    name: 'RV',
                    value: Number(this.rvPercent),
                },
            ]
        },
        studentsFromCourse() {
            if(!this.courseSelect) return []
            return this.students.filter((student) => student.course.id === this.courseSelect)
        }
    },
    methods: {
        onSelectCourse(courseID) {
            this.courseSelect = courseID
        },
        choseMonth() {
            this.$refs.calendar.ChooseDate(this.initMonth)
        },
        async activateAssignment() {
            if (!this.calendarData.dateRange) {
                this.$modal.error(this.$t('library.dates_not_selected'))
                return
            }

            if (!this.calendarData.dateRange.start || !this.calendarData.dateRange.end) {
                this.$modal.error(this.$t('library.dates_not_selected'))
                return
            }

            if (!this.courseSelect) {
                this.$modal.error(this.$t('library.course_not_selected'))
                return
            }

            this.loadingData = true
            const data = {
                id: this.assignmentId,
                book: { id: this.bookId },
                course: { id: this.courseSelect },
                evaluationDate: this.calendarData.dateRange.end,
                startingDate: this.calendarData.dateRange.start,
                teacherEvaluation: 1,
            }
            let response
            if (!this.assignmentId) {
                response = await courseBook.addCourseBook(data).catch((err) => {
                    console.error(err)
                    this.$modal.error(err)
                })
                this.returnAndCleanData(response)
            } else {
                response = await courseBook.validateStudentWithProgress(data).catch((err) => {
                    console.error(err)
                    this.$modal.error(err)
                })
                if (response.status?.name === 'OK') {
                    if (responseValidate.content?.showWarning) {
                        this.$modal.confirm(
                            this.$t('library.confirm_delete'),
                            this.$t(responseValidate.content.message),
                            async () => {
                                response = await courseBook.updateCourseBook(data).catch((err) => {
                                    console.error(err)
                                    this.$modal.error(err)
                            })
                            this.returnAndCleanData(response)
                        })
                    } else {
                        response = await courseBook.updateCourseBook(data).catch((err) => {
                            console.error(err)
                            this.$modal.error(err)
                        })
                        this.returnAndCleanData(response)
                    }
                }
            }
        },
        returnAndCleanData(response) {
            if (response.status?.name === 'ERROR') {
                this.loadingData = false
                this.$modal.error(err)
            } else {
                courseBook.getBookListByCourseAndDate(
                    { courseId: this.courseSelect, date: this.$moment().format('YYYY-MM-DD') },
                    true
                )
                this.loadingData = false
                if (response.content === true) {
                    this.showModal = true
                    this.createAnalyticEvent(AnalyticEventTypes.AssignABook)
                } else if (response.status.name.toLocaleLowerCase() === 'error') {
                    this.$modal.error(response.content)
                }
            }
        },
        sumAllMeasures() {
            const { rv, loc } = this.stats
            return rv + loc + this.stats.in
        },
        closeSuccessActivationModal() {
            this.showSuccessActivation = false
            this.$router.push('/teacher/library/books')
        },
        onActivateAssignation() {
            this.assignBookModal = false
            this.showSuccessActivation = true
        },
        onSeeMoreAssignments() {
            this.showSuccessActivation = false
            this.$router.push('/teacher/library/assignments')
        },
        handledKeepStartDate() {
            if (this.fromPastBookPage && this.start) {
                this.calendarData.dateRange.start = this.originalDate
            }
        },
        openAssignBookModal() {
            this.assignBookModal = true
        },
        closeAssignBookModal() {
            this.assignBookModal = false
        },
        selectCourse(id) {
            this.courseSelected = id
        },
        selectTier(tierOption) {
            this.tierOption = tierOption
        },
        selectStudents(students) {
            this.selectStudents = students
        },
    },
    async mounted() {
        this.loadingData = true
        if(this.assignmentId) {
            const oldAssignationResp = await courseBook.getByCourseBookId(this.assignmentId).catch((err) => {
                console.error(err)
                this.$modal.error(err)
            })
            this.oldAssignation = oldAssignationResp.content[0]
        }

        const data = await bookService
            .getBookAndSummaryMeasureCountById(this.bookId)
            .catch((err) => console.error(err))
        const { book, stats } = data.content
        this.book = book
        this.stats = stats
        this.initMonth = this.$moment().format('YYYY-MM-DD')

        if (this.courseId) this.courseSelect = parseInt(this.courseId, 10)
        if (this.start) {
            this.calendarData.dateRange.start = this.$moment(this.start).format('yyyy-M-D')
            this.originalDate = this.$moment(this.start).format('yyyy-M-D')
        }
        if (this.end) this.calendarData.dateRange.end = this.$moment(this.end).format('yyyy-M-D')

        this.loadingData = false
        this.fromPastBookPage = this.assignFromPastBookPage
    },
}
</script>

<style>
.book-abstract__container {
    overflow-y: auto;
    max-height: 120px;
}
.skills-card {
    box-sizing: border-box;
    border: 2px solid #e9e6f0 !important;
    border-radius: 6px !important;
}

.dropdown-content {
    box-sizing: border-box;
    border: 2px solid #e9e6f0;
    border-radius: 5px;
}

.radio-content {
    border-radius: 5px;
    background-color: #ebe8f4;
}
.vfc-main-container {
    font-family: 'Space Grotesk', cursive !important;
    box-sizing: border-box !important;
    border: 2px solid #e9e6f0 !important;
    border-radius: 6px !important;
    box-shadow: unset;
}

.vfc-start-marked {
    background-color: #24dca9 !important;
}

.vfc-end-marked {
    background-color: #514773 !important;
}

.vfc-calendars {
    flex-direction: column !important;
}

.reserved-date {
    background: #f1c044 !important;
}

.reserved-date-end {
    background: #ff6f3a !important;
}

.detail-item {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-right: 12px;
    margin-bottom: 12px;
}

.details-title {
    font-family: Roboto;
    color: #383838;
}

.book-skills-card {
    box-shadow: 0 0 15px 3px rgba(0,0,0,.1);
}
</style>
