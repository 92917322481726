<template>
    <label>
        <span class="body-2 font-weight-medium grey--text darken-2 py-2" v-if="withLabel">
            {{ label }}
        </span>
        <v-select
            :placeholder="placeholder"
            hide-details
            v-model="localModel"
            :items="items"
            :item-text="defaultItemNameField"
            :item-value="defaultItemValueField"
            outlined
            dense
            solo
            flat
            :label="placeholder"
            :prepend-inner-icon="innerIcon"
            class="shadow-none"
            :disabled="disabled"
        >
            <template v-slot:prepend-item>
                <v-col class="pa-5">
                    <span
                        class="body-2 font-weight-bold"
                        data-cy="generalFilter-courseDetailTitle-span"
                    >
                        {{ placeholder }}
                    </span>
                </v-col>
            </template>

            <template v-slot:selection="{ item }">
                <v-chip
                    color="deep-purple lighten-5"
                    label
                    class="w-100"
                    pill
                    v-if="item === 0 || !item || !item.id || item.id === 0"
                >
                    <span class="deep-purple--text darken-3 body-2 font-weight-medium"
                        >{{ item[defaultItemNameField] || placeholder }}</span
                    >
                </v-chip>

                <v-chip
                    color="primary"
                    label
                    small
                    class="w-100 d-flex flex-row align-center justify-space-between"
                    v-else
                >
                    <span class="white--text body-2 font-weight-medium">
                        {{ item[defaultItemNameField] }}
                    </span>
                    <v-icon v-if="closeButton" size="21" @click="reset()">mdi-close</v-icon>
                </v-chip>
            </template>

            <template v-slot:item="{ item, attrs, on }">
                <v-list-item v-bind="attrs" v-on="on">
                    <v-list-item-action>
                        <v-radio-group v-model="model">
                            <v-radio color="primary" :value="item[defaultItemValueField]"></v-radio>
                        </v-radio-group>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title :id="attrs['aria-labelledby']">
                            <span>{{ item[defaultItemNameField] }}</span>
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </template>
        </v-select>
    </label>
</template>

<script>
export default {
    name: 'SimpleSelect',
    props: {
        items: {
            type: Array,
            required: true
        },
        placeholder: {
            type: String,
            required: false,
            default: 'Pick an option'
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false
        },
        defaultItemValueField: {
            type: String,
            required: false,
            default: 'id'
        },
        defaultItemNameField: {
            type: String,
            required: false,
            default: 'name'
        },
        innerIcon: {
            type: String,
            required: false,
            default: ''
        },
        closeButton: {
            type: Boolean,
            required: false,
            default: false
        },
        closeCallback: {
            type: Function,
            required: false,
            default: () => {}
        },
        withLabel: {
            type: Boolean,
            required: false,
            default: false
        },
        label: {
            type: String,
            required: false,
        },
        model: {
            required: true,
        }
    },
    data() {
        return ({
            localModel: this.model
        })
    },
    watch: {
        localModel(newVal) {
            this.$emit('new-value-selected', newVal)
        },
        model(newVal) {
            this.localModel = newVal
        },
    },
    methods: {
        reset() {
            this.localModel = null
            this.closeCallback()
        }
    }
}
</script>
