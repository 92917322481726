<template>
    <v-dialog
        v-model="dialog"
        persistent
        transition="dialog-bottom-transition"
        width="90%"
        max-width="900px"
        overlay-color="black"
        content-class="rounded-xl elevation-0"
    >
        <v-card
            data-cy="success-book-assignment-modal"
            color="light"
            class="pa-9 d-flex flex-column align-items-center justify-content-center text-center"
        >
            <h1 class="my-3">
                {{ $t('library.success_book_assignation_title') }}
            </h1>

            <p class="body-1 p-6">
                {{ $t('library.success_book_assignation_msg') }}
            </p>

            <!-- Action buttons -->
            <v-row class="my-3" align-content="center" justify="center">
                <v-btn
                    elevation="0"
                    class="mx-3"
                    large
                    color="deep-purple lighten-5"
                    @click="closeModal"
                >
                    <span class="primary--text text-center text-capitalize body-1 font-weight-medium">
                        <v-icon color="primary" small>
                            mdi-arrow-left
                        </v-icon>
                        <span class="text-center text-capitalize body-1 font-weight-medium">
                            {{ $t('assignments.actions.goBackToLibrary') }}
                        </span>
                    </span>
                </v-btn>

                <v-btn elevation="0" class="mx-3" large color="primary" @click="seeMoreAssignedBooks">
                    <v-icon small class="mr-2">mdi-clipboard-check-multiple</v-icon>
                    <span class="text-center text-capitalize body-1 font-weight-medium">
                        {{ $t('assignments.actions.seeAssignedBooks') }}
                    </span>
                </v-btn>
            </v-row>

        </v-card>
    </v-dialog>
</template>

<script>
import img from '@/assets/img/library/bookStar.svg'
export default {
    name: 'SuccessBookAssignment',
    props: {
        dialog: { type: Boolean, default: true },
        bookTitle: { type: String, default: 'Book Title' },
    },
    data: () => ({
        modalImg: img,
    }),
    methods: {
        closeModal() {
            this.$emit('close-modal')
            this.dialog = false
        },
        seeMoreAssignedBooks() {
            this.$emit('see-more-assignments')
        }
    },
}
</script>

<style scoped>
.class {
    color: #4a4866aa;
}
</style>
