<template>
    <v-menu
        v-model="dateMenu"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="auto"
        :disabled="disabled"
    >
        <template v-slot:activator="{ on, attrs }">
            <v-text-field
                v-model="date"
                prepend-inner-icon="mdi-calendar"
                readonly
                outlined
                v-bind="attrs"
                :placeholder="placeholder"
                v-on="on"
                color="primary"
                hide-details
                dense
                :disabled="disabled"
            >
            </v-text-field>
        </template>
        <v-date-picker
            hidden
            v-model="date"
            @input="dateMenu = false"
            :min="min"
        ></v-date-picker>
    </v-menu>
</template>

<script>
export default {
    name: 'SingleDateInput',
    props: {
        placeholder: {
            type: String,
            required: false,
            default: 'Pick a date'
        },
        currentValue: {
            type: String,
            required: false,
            default: ''
        },
        disabled: {
            required: false,
            default: false,
            type: Boolean
        },
        min: {
            required: false,
            default: null,
            type: String
        },
    },
    data() {
        return {
            date: this.currentValue,
            dateMenu: false
        }
    },
    watch: {
        date(newVal) {
            this.$emit('date-picked', newVal)
        },
        currentValue(newVal) {
            this.date = newVal
        }
    }
}
</script>
