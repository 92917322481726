import Services from './base-services'

const service = new Services('courseBook')
import mapParameters from '../utils/map-parameters'

export interface CourseBook {
    id: number
    course: any
    book: any
    evaluationDate: string
    startingDate: string
    teacherEvaluation: number
}

export default {
    getListByTeacherId(teacherId: number) {
        const params = mapParameters({
            teacherId: teacherId ? teacherId : null,
        })
        return service.get(`getListByTeacherId?teacherId=${teacherId}`, params)
    },
    addCourseBook(courseBook: CourseBook) {
        return service.post(`addCourseBook`, courseBook)
    },
    getByCourseBookId(courseBookId: number) {
        const params = mapParameters({
            courseBookId,
        })
        return service.get('getByCourseBookId', params)
    },
    updateCourseBook(courseBook: CourseBook) {
        return service.put('updateCourseBook', courseBook)
    },
    validateStudentWithProgress(courseBook: CourseBook) {
        return service.post('validateStudentWithProgress', courseBook)
    },
    deleteCourseBook(id: number) {
        return service.delete(`deleteCourseBook?id=${id}`)
    },
    getBookListByCourseAndDate(data: any, replaceCache = false) {
        const requestUrl = `getBookListByCourseAndDate?courseId=${data.courseId}&date=${data.date}`;
        return service.get(requestUrl, undefined, true, replaceCache, `${requestUrl}`)
    },
}
