<template>
    <v-dialog
        v-model="dialog"
        persistent
        transition="dialog-bottom-transition"
        width="90%"
        max-width="900px"
        overlay-color="black"
        content-class="rounded-xl elevation-0"
    >
        <v-card
            color="white"
            class="pa-9 d-flex flex-column align-items-center justify-content-center text-center"
        >
            <h1 class="my-3" v-if="!oldAssignation">
                {{ $t('assignments.titles.assignTheBook') }} <span class="text-capitalize primary--text"> {{ bookTitle }} </span>
            </h1>
            <h1 class="my-3" v-else>
                {{ $t('assignments.titles.modifyTheAssignation') }}
            </h1>

            <!-- ------------ -->
            <!-- Date Pickers -->
            <!-- ------------ -->
            <v-row class="grey lighten-5 px-9 py-6 rounded-lg">
                <v-row class="rounded-lg">
                    <!-- Start date -->
                    <v-col
                        data-cy="start-date-datepicker"
                        cols="12"
                        md="6"
                        class="d-flex flex-column text-start align-items-start justify-content-start"
                    >
                        <span class="body-2 grey--text font-weight-medium my-1">
                            {{ $t('assignments.inputs.dates.startDate') }}
                        </span>
                        <SingleDateInput
                            :currentValue="startDate"
                            @date-picked="handleStartingDate"
                            :placeholder="$t('assignments.placeholders.dates')"
                            :disabled="oldAssignation"
                        />
                    </v-col>

                    <!-- End date -->
                    <v-col
                        data-cy="end-date"
                        cols="12"
                        md="6"
                        class="d-flex flex-column text-start align-items-start justify-content-start"
                    >
                        <span class="body-2 grey--text font-weight-medium my-1">
                            {{ $t('assignments.inputs.dates.endDate') }}
                        </span>
                        <SingleDateInput
                            :currentValue="endDate"
                            :minDate="new Date(startDate) < new Date() ? new Date().toISOString().substr(0, 10) : new Date(startDate).toISOString().substr(0, 10)"
                            @date-picked="handleEvaluationDate"
                            :placeholder="$t('assignments.placeholders.dates')"
                            :disabled="!startDate ? true : false"
                        />
                    </v-col>
                </v-row>

                <!-- ------------- -->
                <!-- Course Picker -->
                <!-- ------------- -->
                <v-row class="rounded-lg my-2">
                    <!-- Course -->
                    <v-col
                        data-cy="course-picker"
                        cols="12"
                        md="6"
                        class="d-flex flex-column text-start align-items-start justify-content-start"
                    >
                        <span class="body-2 grey--text font-weight-medium my-1">
                            {{ $t('assignments.inputs.course.pick') }}
                        </span>
                        <SimpleSelect
                            defaultItemNameField="name"
                            :items="courses"
                            :model="currentCourse"
                            :value="currentCourse"
                            :placeholder="$t('assignments.placeholders.course')"
                            innerIcon="mdi-folder"
                            :disabled="!startDate || !endDate ? true : false || oldAssignation"
                            @new-value-selected="(id) => (currentCourse = id)"
                        />
                    </v-col>

                    <!-- ------------------------ -->
                    <!-- Students from the course -->
                    <!-- ------------------------ -->
                    <v-col
                        data-cy="students-picker"
                        cols="12"
                        md="6"
                        class="d-flex flex-column text-start align-items-start justify-content-start"
                    >
                        <span class="body-2 grey--text font-weight-medium my-1">
                            {{ $t('assignments.inputs.students.assignTo') }}
                        </span>
                        <StudentsSelectPerTier
                            data-cy="student-picker"
                            :students="studentsFromCourse"
                            :model="pickedStudents"
                            @tier-picked="handleTierPicked"
                            @add-tier="addTier"
                            @all-students-option="handleAllStudentsOption"
                            @update:model="handlePickedStudents"
                            :isDisabled="!currentCourse"
                            :placeholder="$t('assignments.placeholders.students')"
                        />
                    </v-col>
                </v-row>

                <!-- -------------- -->
                <!-- Name of the assignation -->
                <!-- -------------- -->
                <v-row class="rounded-lg w-100">
                    <v-col
                        cols="12"
                        class="d-flex flex-column text-start align-items-start justify-content-start"
                    >
                        <span class="body-2 grey--text font-weight-medium my-1">
                            {{ $t('assignments.inputs.assignation.name') }}
                        </span>
                        <v-text-field
                            v-model="assignationName"
                            prepend-inner-icon="mdi-pencil"
                            outlined
                            dense
                            hide-details
                            :placeholder="$t('assignments.placeholders.assignation')"
                            :disabled="pickedStudents.length > 0 ? false : true || oldAssignation"
                        ></v-text-field>
                    </v-col>
                </v-row>
            </v-row>

            <!-- Action buttons -->
            <v-row class="my-3" align-content="center" justify="center">
                <v-btn
                    elevation="0"
                    class="mx-3"
                    large
                    outlined
                    color="primary"
                    @click="closeModal"
                >
                    <span class="text-center text-capitalize body-1 font-weight-medium">
                        {{ $t('assignments.actions.cancel') }}
                    </span>
                </v-btn>

                <v-btn
                    data-cy="activate-assignation-btn"
                    elevation="0"
                    class="mx-3"
                    large
                    color="primary"
                    @click="activateAssignation"
                    :loading="loadingData"
                    :disabled="!isAbleToCreateAssignation"
                >
                    <v-icon small class="mr-2">mdi-clipboard-check-multiple</v-icon>
                    <span class="text-center text-capitalize body-1 font-weight-medium">
                        {{ !oldAssignation ? $t('assignments.actions.activate') : $t('assignments.actions.modify') }}
                    </span>
                </v-btn>
            </v-row>
        </v-card>
    </v-dialog>
</template>
<script>
import { mapState } from 'vuex'
import SimpleSelect from '../../core/forms/selects/simpleSelect/index.vue'
import SingleDateInput from '../../core/forms/dates/singleDate/index.vue'
import StudentsSelectPerTier from '../../core/forms/selects/studentsSelect/index.vue'
import courseBookServices from '@/services/courseBook'

export default {
    name: 'ActivateBookAssignation',
    components: {
        SimpleSelect,
        SingleDateInput,
        StudentsSelectPerTier,
    },
    props: {
        dialog: { type: Boolean, default: true },
        bookTitle: { type: String, default: 'Book Title' },
        bookId: { type: Number, required: true },
        courses: { type: Array, default: [], required: true },
        students: { type: Array, default: [], required: true },
        assignationId: { type: Number, required: false },
        pastAssignation: { type: Boolean, default: false, required: false },
        oldAssignation: { type: Object, default: null, required: false }
    },
    data() {
        return {
            assignationName: '',
            pickedStudents: [],
            currentCourse: null,
            startDate: null,
            endDate: null,
            loadingData: false,
        }
    },
    computed: {
        ...mapState({
            teacher: (state) => state.userData.user,
        }),
        studentsFromTier1() {
            return this.students.filter((student) => student.tier === 'TIER_GROUP_1')
        },
        studentsFromTier2() {
            return this.students.filter((student) => student.tier === 'TIER_GROUP_2')
        },
        studentsFromTier3() {
            return this.students.filter((student) => student.tier === 'TIER_GROUP_3')
        },
        studentsFromNoTier() {
            return this.students.filter((student) => !['TIER_GROUP_1', 'TIER_GROUP_2', 'TIER_GROUP_3'].includes(student.tier) || !student.tier)
        },
        selectedStudentsFromTier1() {
            return this.pickedStudents.filter((studentID) => {
                return this.studentsFromTier1.find((s) => s.id === studentID)
            })
        },
        selectedStudentsFromTier2() {
            return this.pickedStudents.filter((studentID) => {
                return this.studentsFromTier2.find((s) => s.id === studentID)
            })
        },
        selectedStudentsFromTier3() {
            return this.pickedStudents.filter((studentID) => {
                return this.studentsFromTier3.find((s) => s.id === studentID)
            })
        },
        selectedStudentsFromNoTier() {
            return this.pickedStudents.filter((studentID) => {
                return this.studentsFromNoTier.find((s) => s.id === studentID)
            })
        },
        studentsFromCourse(){
            return this.students
        },
        isAbleToCreateAssignation() {
            return (
                this.currentCourse &&
                this.startDate &&
                this.endDate &&
                this.pickedStudents.length > 0
            )
        },
    },
    methods: {
        closeModal() {
            this.$emit('close-modal')
            this.dialog = false
        },
        async activateAssignation() {
            this.loadingData = true

            const addCourseBookData = {
                id: null,
                course: { id: this.currentCourse },
                book: { id: Number(this.bookId) },
                startingDate: this.startDate,
                evaluationDate: this.endDate,
                group: {
                    id: null,
                    name: this.assignationName || '',
                    owner: this.teacher.id,
                    users: this.getOnlyPickedStudentsIDs(),
                    groupType: this.getGroupType(),
                    tierName: this.getGroupTierName()
                },
                teacherEvaluation: 1,
            }

            if (this.pastAssignation) {
                // Update new assignation
                addCourseBookData.id = this.assignationId
                addCourseBookData.group.id = this.oldAssignation.group
                    && this.oldAssignation.group.id

                try {
                    const responseValidate = await courseBookServices.validateStudentWithProgress(addCourseBookData)
                    if (responseValidate.status?.name === 'OK') {
                        if (responseValidate.content?.showWarning) {
                            this.loadingData = false
                            this.$modal.confirm(
                            this.$t('library.confirm_delete'),
                            this.$t(responseValidate.content.message),
                            async () => {
                                this.updateAssign(addCourseBookData)
                            })
                        } else {
                            this.updateAssign(addCourseBookData)
                        }
                    } else {
                        throw new Error(responseValidate.content)
                    }

                } catch (error) {
                    this.loadingData = false
                    this.$modal.error(error)
                }
            } else {
                // Create new assignation
                try {
                    this.loadingData = true
                    const resp = await courseBookServices.addCourseBook(addCourseBookData)
                    if (resp.status?.name === 'ERROR') {
                        this.loadingData = false
                        this.$modal.error(resp.content)
                        throw new Error(resp.content)
                    }
                    this.dialog = false
                    this.loadingData = false
                    this.$emit('activate-assignation')
                } catch (error) {
                    this.loadingData = false
                    this.$modal.error(error)
                }
            }

        },
        async updateAssign(addCourseBookData) {
            const resp = await courseBookServices.updateCourseBook(addCourseBookData)
            if (resp.status?.name === 'ERROR') {
                this.loadingData = false
                this.$modal.error(resp.content)
                throw new Error(resp.content)
            }
            await courseBookServices.getBookListByCourseAndDate(
                { courseId: this.currentCourse, date: this.$moment().format('YYYY-MM-DD') },
                true
            )
            this.dialog = false
            this.loadingData = false
            this.$emit('activate-assignation')
        },
        getTierColor(tierID) {
            switch (tierID) {
                case 1:
                    return 'green darken-2'
                case 2:
                    return 'amber accent-3'
                case 3:
                    return 'red darken-2'
                default:
                    return 'green darken-3'
            }
        },
        handleStartingDate(newDate) { this.startDate = newDate },
        handleEvaluationDate(newDate) { this.endDate = newDate },
        handleItemsGroupOption(tierID) {
            this.$emit('callback-items-group-option', tierID)
        },
        handleTierPicked(tierID) {
            const studentsFromTier =  tierID === 'NO_TIER' ? this.students.filter(
                (student) => !['TIER_GROUP_1', 'TIER_GROUP_2', 'TIER_GROUP_3'].includes(student.tier) || !student.tier
            ) : this.students.filter(
                (student) => student.tier === tierID
            )

            // If the tier is already picked, remove it from the picked students
            if (this.pickedStudents.includes(tierID)) {
                this.pickedStudents = this.pickedStudents.filter((id) => id !== tierID)
                studentsFromTier.forEach((student) => {
                    if (this.pickedStudents.includes(student.id))
                        this.pickedStudents = this.pickedStudents.filter(
                            (id) => id !== student.id
                        )
                })
            } else {
                // If the tier is not picked, add it to the picked students
                this.pickedStudents.push(tierID)
                studentsFromTier.forEach(student => {
                    if (!this.pickedStudents.includes(student.id)) this.pickedStudents.push(student.id)
                })
            }
        },
        addTier(tierID) {
            if (!this.pickedStudents.includes(tierID)) {
                this.pickedStudents = [...this.pickedStudents, tierID]
            }
        },
        pickAllStudents(){
            this.pickedStudents = this.students.map((student) => student.id).filter(student => student !== null && student !== undefined)
            this.pickedStudents.push('ALL', 'TIER_GROUP_1', 'TIER_GROUP_2', 'TIER_GROUP_3', 'NO_TIER')
        },
        handleAllStudentsOption() {
            if(this.pickedStudents.length >= this.students.length) {
                this.pickedStudents = []
            } else {
                this.pickAllStudents()
            }
        },
        getTierStudents(tierID) {
            return this.students.filter(student => student.tier === tierID)
        },
        handlePickedStudents(picked) {
            this.pickedStudents = picked

            if(picked.length !== 0 && !this.pickedStudents.includes('STUDENTS')) {
                this.pickedStudents.push('STUDENTS')
            }

            if(picked.length === 1 && this.pickedStudents.includes('STUDENTS')) {
                this.pickedStudents = this.pickedStudents.filter(id => id !== 'STUDENTS')
            }
        },
        getOnlyPickedStudentsIDs() {
            const students = this.pickedStudents.filter(studentID => !['ALL', 'TIER_GROUP_1', 'TIER_GROUP_2', 'TIER_GROUP_3', 'NO_TIER', 'STUDENTS'].includes(studentID))
            return students.map(studentID => ({ id: studentID }))
        },
        getGroupType() {
            if(this.pickedStudents.includes('ALL')) return null
            const tiersSelected = []
            const studentsFromTiers = []

            // Checking if there are students from different tiers
            this.getOnlyPickedStudentsIDs().forEach(studentID => {
                const currStudent = this.students.find(student => student.id === studentID.id)
                if(currStudent) {
                    if(!['TIER_GROUP_1', 'TIER_GROUP_2', 'TIER_GROUP_3'].includes(currStudent.tier) || !currStudent.tier) {
                        if(!studentsFromTiers.includes('NO_TIER')) studentsFromTiers.push('NO_TIER')
                    } else {
                        if(!studentsFromTiers.includes(currStudent.tier)) studentsFromTiers.push(currStudent.tier)
                    }
                }
            })

            // Checking if there is only students from one tier
            if(studentsFromTiers.length > 1) return 'CUSTOM'
            else {
                if(this.pickedStudents.includes(studentsFromTiers[0])) tiersSelected.push(studentsFromTiers[0])
                if(tiersSelected.length > 1) return 'CUSTOM'
                else if(tiersSelected.length === 1) return 'TIER'
            }

            return 'CUSTOM'
        },
        getGroupTierName() {
            if(this.getGroupType() === 'TIER') {
                const allStudentsIDs = this.getOnlyPickedStudentsIDs()
                const student = this.students.find(student => student.id == allStudentsIDs[0].id)
                return student.tier
            }

            return null
        },
        setOldAssignationData() {
            this.assignationName = this.oldAssignation.group?.name
            this.startDate = this.oldAssignation.startingDate.substring(0, 10)
            this.endDate = this.oldAssignation.evaluationDate.substring(0, 10)
            this.currentCourse = this.oldAssignation.course.id
            setTimeout(() => {
                if(this.oldAssignation.group) {
                    this.pickedStudents = this.oldAssignation.group.users.map(user => user.id)
                } else {
                    this.pickedStudents = this.pickAllStudents()
                }
            }, 100)
        },
    },
    watch: {
        students(newStudents){
            this.students = newStudents
            this.pickedStudents = []
        },
        currentCourse(courseID) {
            this.$emit('on-select-course', courseID)
            if(this.oldAssignation) {
                setTimeout(() => {
                    if(courseID != this.oldAssignation.course.id) {
                        this.pickAllStudents()
                    } else {
                        if(this.oldAssignation.group) {
                            this.pickedStudents = this.oldAssignation.group.users.map(user => user.id)
                        } else {
                            this.pickAllStudents()
                        }
                    }
                }, 100)
            } else {
                setTimeout(() => {
                    this.pickAllStudents()
                }, 100)
            }

        },
        pickedStudents(picked) {
            // If all students are picked, add the 'ALL' option
            if(this.getOnlyPickedStudentsIDs().length >= this.students.length) {
                if(!this.pickedStudents.includes('ALL')) {
                    this.pickedStudents.push('ALL')
                }
            } else {
                if(this.pickedStudents.includes('ALL')) this.pickedStudents = this.pickedStudents.filter(id => id !== 'ALL')
            }

            // If all students from a tier are picked, add the TIER option

            // TIER_GROUP_1
            if(this.studentsFromTier1.length === this.selectedStudentsFromTier1.length) {
                if(!this.pickedStudents.includes('TIER_GROUP_1')) {
                    this.pickedStudents.push('TIER_GROUP_1')
                }
            } else {
                if(this.pickedStudents.includes('TIER_GROUP_1')) this.pickedStudents = this.pickedStudents.filter(id => id !== 'TIER_GROUP_1')
            }

            // TIER_GROUP_2
            if(this.studentsFromTier2.length === this.selectedStudentsFromTier2.length) {
                if(!this.pickedStudents.includes('TIER_GROUP_2')) {
                    this.pickedStudents.push('TIER_GROUP_2')
                }
            } else {
                if(this.pickedStudents.includes('TIER_GROUP_2')) this.pickedStudents = this.pickedStudents.filter(id => id !== 'TIER_GROUP_2')
            }

            // TIER_GROUP_3
            if(this.studentsFromTier3.length === this.selectedStudentsFromTier3.length) {
                if(!this.pickedStudents.includes('TIER_GROUP_3')) {
                    this.pickedStudents.push('TIER_GROUP_3')
                }
            } else {
                if(this.pickedStudents.includes('TIER_GROUP_3')) this.pickedStudents = this.pickedStudents.filter(id => id !== 'TIER_GROUP_3')
            }

            // NO_TIER
            if(this.studentsFromNoTier.length === this.selectedStudentsFromNoTier.length) {
                if(this.selectedStudentsFromNoTier > 0 && !this.pickedStudents.includes('NO_TIER')) {
                    this.pickedStudents.push('NO_TIER')
                }
            } else {
                if(this.pickedStudents.includes('NO_TIER')) this.pickedStudents = this.pickedStudents.filter(id => id !== 'NO_TIER')
            }


        }
    },
    async mounted() {
        if(this.pastAssignation && this.assignationId) {
            this.setOldAssignationData()
        }
    },
}
</script>
