import Services from './base-services'
import global from '@/constants/GlobalConstant'
import mapParameters from '../utils/map-parameters'

const service = new Services('book')

// // userId=128180&grade=&matchGradeAndAge=true&tittle=&number=0&size=10
interface ISearchBooksLibrary {
    userId?: number
    grade?: number
    matchGradeAndAge?: boolean
    tittle?: string
    number?: number
    size?: number
}

interface ISearchBooksLibraryNew {
    bookUserId?: number
    categoryIdList?: Array<number>
    resourceType?: string
    textTypeIdList?: Array<number>
    searchQuery?: string,
    ageList?: Array<number>
    countryIdList?: Array<number>
    lexileStart?: number
    lexileEnd?: number
}

export default {
    getAllPayBooks(number: number, size?: number) {
        const params = mapParameters({
            number: number ? number : 0,
            size: size ? size : 15,
        })
        return service.get('getAllPayBooks', params)
    },
    getAllPayBookByUser(userId: number | string, number: number, size?: number) {
        const params = mapParameters({
            userId: userId ? userId : null,
            number: number ? number : 0,
            size: size ? size : 15,
        })
        return service.get('getAllPayBookByUser', params)
    },
    getAllPayBooksByUser(userId: number | string, number: number, size?: number) {
        const params = mapParameters({
            userId: userId ? userId : null,
            number: number ? number : 0,
            size: size ? size : 15,
        })
        return service.get('getAllPayBooksByUser', params)
    },
    getBookPageByTitleAndParams(
        data: ISearchBooksLibraryNew,
        pageSize?: number,
        pageNumber?: number
    ) {
        const params = {
            bookUserId: data.bookUserId ? data.bookUserId : null,
            categoryIdList: data.categoryIdList ? data.categoryIdList : null,
            resourceType: data.resourceType ? data.resourceType : null,
            textTypeIdList: data.textTypeIdList ? data.textTypeIdList : null,
            searchQuery: data.searchQuery ? data.searchQuery : null,
            pageNumber: pageNumber ? pageNumber : 0,
            pageSize: pageSize ? pageSize : 15,
            ageList: data.ageList ? data.ageList : null,
            countryIdList: data.countryIdList ? data.countryIdList : null,
            lexileStart: data.lexileStart ? data.lexileStart : null,
            lexileEnd: data.lexileEnd ? data.lexileEnd : null
        }
        return service.post('getBookPageByTitleAndParams', params)
    },
    getTopRatingPage(id: any) {
        return service.get(`getTopRatingPage?userId=${id}&number=0&size=10`)
    },
    getBookListFavoriteByUser(id: any) {
        return service.post(`getBookListFavoriteByUser?userId=${id}`)
    },
    getNewBooksPage(id: any) {
        return service.get(`getNewBooksPage?userId=${id}&number=0&size=10`)
    },
    getCompiledListOfUser(id: any) {
        return service.post(`getCompiledListOfUser?userId=${id}`)
    },
    getBookListByUser(id: any) {
        return service.post(`getBookListByUser?userId=${id}`)
    },
    getBookListByGrade(id: any) {
        return service.get(`getBookListByGrade?grade=${id}`)
    },
    getBookListSuggestedForUser(id: any) {
        return service.post(`getBookListSuggestedForUser?userId=${id}`)
    },
    getBookListByTittle(data: any) {
        return service.get(
            `getBookListByTittle?tittle=${data.title}&number=${data.number}&size=${data.size}`
        )
    },
    getBookListByTittleLibrary(data: ISearchBooksLibrary) {
        const params = mapParameters({
            userId: data.userId ? data.userId : null,
            grade: data.grade ? data.grade : null,
            matchGradeAndAge: data.matchGradeAndAge ? data.matchGradeAndAge : false,
            tittle: data.tittle ? data.tittle : null,
            number: data.number ? data.number : 0,
            size: data.size ? data.size : 30,
        })
        return service.get('getBookListByTittle', params)
    },
    getBookListByTitle(title: any) {
        return service.get(`getBookListByTitle?tittle=${title}`)
    },
    // TODO Mejorar
    getChapterContentHTML(url: string) {
        return fetch(url)
    },
    getBookSummaryInfo(bookId: number, userId: number) {
        return service.get(`getBookSummaryInfo?bookId=${bookId}&userId=${userId}`)
    },
    getBookById(bookId: number | string) {
        return service.get(`getBookById?bookId=${bookId}`)
    },
    getBookAndSummaryMeasureCountById(bookId: number | string) {
        return service.get(`getBookStatsSummaryInfo?bookId=${bookId}`)
    },
    getEPUBBookContent(resourceDir: any) {
        return fetch(`${global.BASE_URL_BOOK}${resourceDir}/OEBPS/content.opf`)
    },
    getEPUBBookPage(resourceDir: any, epubPageTarget: any) {
        return fetch(`${global.BASE_URL_BOOK}${resourceDir}/OEBPS/${epubPageTarget}`)
    },
    getBooksAddedRecentlyByQty(size: number | string) {
        return service.get(`getBooksAddedRecentlyByQty?size=${size}`)
    },
    getBooksAddedRecentlyByDate(date: any, size: number | string) {
        return service.get(`getBooksAddedRecentlyByDate?toDate=${date}&size=${size}`)
    },
}
